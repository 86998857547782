import React from 'react'
import "./about.css"
import ME from "../../Assets/pdp.jpg"
import CTA from "./../header/CTA"
import HeaderSocials from './../header/HeaderSocials'
import {FaAward} from "react-icons/fa"
import {FiUsers} from "react-icons/fi"
import {VscFolderLibrary} from "react-icons/vsc"

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>20+ Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Orders</h5>
              <small>50+ Completed</small>
            </article>
            
          </div>
          

          <p>I am a computer engineer actively working in two scientific research projects. First project <a href="https://projekt-inspirer.de/">SparePartAssist</a> with the collaboration of CADENAS, the Berlin University of Applied Sciences (HTW), the German Society for the Advancement of Applied Computer Science (GFal) and Keßler Real Estate Solutions GmbH is deeply involved in geometric similarity, 3D object recognition,  AR-based assistance and mobile developement. Second project <a href="https://www.htw-berlin.de/forschung/online-forschungskatalog/projekte/projekt/?eid=3101">Inspirer</a>, is a cooperative mixed reality application for urban planning immersing point cloud-based tracking methods and both (AR/VR) user interfaces for the the system variants.</p>
          {/* <a href="#contact" className='btn btn-primary'>Let's Talk</a> */}
          <CTA/>
        </div>
      </div>
    </section>
  )
}

export default About