import React , {useState} from 'react'
import "./Drinkapp.css"
import { useLocation } from 'react-router-dom';
import logo from "../../Assets/transparent.png"
import artboard from "../../Assets/Drinkapp/Artboard_white.png"
import {IoLogoGooglePlaystore} from "react-icons/io5"

function Drinkapp() {
    const {search} = useLocation();
    const [activeNav, setActiveNav]= useState("#presentaion");
    const [nav, setNav]= useState(false);

    const changeBackground = () => {
        if(window.scrollY >= 50){
            setNav(true);
        } 
        else {
            setNav(false);
        }
    }

    window.addEventListener('scroll', changeBackground)

  return (
    <body id="contained-drink-app" className="App">
            <div id = "main">
                <nav className={activeNav ? "nav-active" : "#contained-drink-app"}>
                    <a href="/" className="logo">
                        <img src={logo} alt="" />
                    </a>
                    <input type="checkbox" className="menu-btn" id="menu-btn" />
                    <label className="menu-icon" for="menu-btn">
                        <span className="nav-icon"></span>
                    </label>
                    <ul className="menu">
                        <li><a href="#" onClick={() => setActiveNav("#")} className={activeNav === "#" ? "active" : ""}>Home</a></li>
                        <li><a href="#about" onClick={() => setActiveNav("#about")} className={activeNav === "#about" ? "active" : ""}>Download</a></li>
                        <li><a href="#presentaion" onClick={() => setActiveNav("#presentaion")} className={activeNav === "#presentaion" ? "active" : ""}>Features</a></li>
                    </ul>
                </nav>
            </div>
            <div className="name">
                <h1><span>Driking Game</span> for friends, couples and adults</h1>
                <p className="details">This is a completely free questions Game. No ads, no payment. Everything unlocked, all you need to do is download and play with your friends, your date or partner!</p>
                <p className="details">Available for android and coming to IOS</p>
                <a href="https://play.google.com/store/apps/details?id=com.MahersDesigns.DrinkItGame" className="cv-btn">Download <pre> </pre><IoLogoGooglePlaystore/></a>
            </div>
            <div id="about">
                <div className='about-image'>
                    <img src={artboard} alt="" />
                </div>
                <div className="about-text">
                    <h2>DrinkIt Game</h2>
                    <p>Get to know your friends by playing a fun game of questions! This is the perfect group party game for friends, couples and adults.
                DrinkIt contains hundreds of the best fun and challenging questions ranging from clean to extremly spicy ones and a misterious category.
                This is the ideal Q&A for parties and sleepovers.</p>
                    <a href="https://play.google.com/store/apps/details?id=com.MahersDesigns.DrinkItGame"><button>Download</button></a>
                </div>
            </div>
            <div id="presentaion">
                <h1>UI Presentation</h1>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequatur, nihil! Inventore reiciendis quo itaque ab excepturi eum consectetur ipsam obcaecati voluptatibus voluptatem, assumenda debitis a provident corporis amet nihil aperiam.</p>
            </div>
    </body>
  )
}

export default Drinkapp