import React from 'react'
import "./footer.css"
import {FaArtstation} from "react-icons/fa"
import {FiInstagram} from 'react-icons/fi'
import {RiTwitterLine} from 'react-icons/ri'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer_logo'>Maher</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services"Services></a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a target="_blank" href="https://www.artstation.com/youngskills"><FaArtstation/></a>
        <a target="_blank" href="https://www.instagram.com/devnest.contact/"><FiInstagram/></a>
        <a target="_blank" href="https://twitter.com/DevNest3"><RiTwitterLine/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; MahersDesigns. All rights reserved 2022.</small>
      </div>
    </footer>
  )
}

export default Footer