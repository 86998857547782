import React from 'react'
import "./testimonials.css"
import AVTR from "../../Assets/user.png"

import {Pagination , Navigation } from 'swiper';
import {Swiper , SwiperSlide} from "swiper/react"
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';

import Flags from 'country-flag-icons/react/3x2'

const data = [
{
  avatar: AVTR,
  name: 'aziiz1996',
  country: 'Qatar',
  flag: <Flags.QA a className='client__flag'/>,
  review: "Better than excepted, Appreciate the extra effort spent in delivering my order . Straight and forward 👌🏻"
},
{
  avatar: AVTR,
  name: 'jsmith2828',
  country: 'United sates',
  flag: <Flags.US a className='client__flag'/>,
  review: "very good communication and working with revisions... thank you thank you"
},
{
  avatar: AVTR,
  name: 'eyspace',
  country: 'United states',
  flag: <Flags.US a className='client__flag'/>,
  review: "Great experience, exceeded expectations.",
},
{
  avatar: AVTR,
  name: 'luisahergert',
  country: 'Germany',
  flag: <Flags.DE a className='client__flag'/>,
  review: "Ich bin sehr zufrieden mit dem Ergebnis. Die Kommunikation verlief super und das Ergebnis kam sehr schnell.",
},
{
  avatar: AVTR,
  name: 'spiegelprofi',
  country: 'Germany',
  flag: <Flags.DE a className='client__flag'/>,
  review: "Wir sind sehr zufrieden mit den Bildern. All unsere Wünsche werden berücksichtig und umgesetzt. Immer wieder gerne!",
},
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container" pagination={true} modules={[Pagination]}>
        {
          data.map(({avatar, name , country, flag, review}, index) =>{
            return(
              <SwiperSlide key={index} className='testimonial'>
                <div className="client__avatar">
                  <img src={avatar} />
                </div>
                
                <h5 className='client__name'>{name}</h5>
                <h5>{country} {flag}</h5>
                
                  <small className='cilent__review'>{review}</small>
        </SwiperSlide>
            )
          })
        }
        
      </Swiper>
    </section>
  )
}

export default Testimonials