export default [
    {
        year : '15-01-2024',
        title: 'Core Mechanics',
        duration: '1 Month',
        details : 'Implemented cooking , cutting and interaction with the base counters and all application core mechanics like sound and effect and UI interactions.',
    },
    {
        year : '15-02-2024',
        title: 'Main World',
        duration: '1 Month',
        details : 'Designed and added main world and implemented two different types of missions with their SFX.',
    },
    {
        year : '15-03-2024',
        title: 'More Core Mechanics',
        duration: '1 Month',
        details : 'Designed and Implemented new counter including washing and implemented new ingredients with their respective recipes.',
    },
    {
        year : '15-04-2024',
        title: 'Mission Timeline',
        duration: '1 Month',
        details : 'Finalized mission and Implemented post mission timelines as well as fixed light and graphical components and imported new UI and sound effects.',
    },
    {
        year : 'Today',
        title: 'Mission 2-2',
        duration: '1 Month',
        details : 'Successfully redesigned character and belonging animation and designed mission 2-2 and now implementing new challenge mechanic for the respective mission.',
    },
]
