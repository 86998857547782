import React , {useState} from 'react'
import "./StreetBitesUpdates.css"
import { useLocation } from 'react-router-dom';

function StreetBitesUpdates(){
    return(
        <body id="contained-streetbites-updates" className='App'>
            <div className='streetbites-updates'>
                <h1>Devlog Entry - January 28, 2024</h1>
                <h2>Title: StreetBites odyssey - #1 Devlog</h2>
                <h2>Overview:</h2>
                <p>In today's devlog entry, I'll be sharing the latest progress and updates on development of StreetBites - A Culinary Adventure, my solo game development project.</p>
                <h2>Development Progress:</h2>
                    <p>- Core Mechanics Implementation: Over the past week, I focused on implementing the core gameplay mechanics, including cooking, chopping, and serving mechanics. 
                    Players can now dash around the kitchen, chop ingredients, cook dishes on the stove, and serve orders to customers.</p>
                    <p>- Food Truck Simulation: I've laid the groundwork for the food truck simulation aspect of the game. Players can drive their food truck around the city, catering to different events and fulfilling customer orders. 
                    The food truck soon will be customizable and upgradable to unlock new abilities and recipes.</p>
                    <p>- Progression System: I've started work on implementing a progression system where players can earn in-game coins by completing missions and fulfilling customer orders.
                         These coins will soon be used to upgrade the food truck, unlock new recipes, and improve kitchen equipment for faster cooking.</p>
                    <p>- Mission Design: I've designed the missions for the first world, which focuses on burgers and salads. 
                        Each mission introduces new challenges and recipes, gradually increasing in difficulty to provide players with a satisfying learning curve.</p>
                    <h2>Challenges Faced:</h2>
                    <p>- Balancing Difficulty: Balancing the difficulty curve for the missions has been a challenge.
                         I've been playtesting extensively to ensure that each mission provides an appropriate level of challenge without becoming frustrating for players.</p>
                    <p>- Art and Visuals: As a solo developer, creating high-quality art assets has been time-consuming. 
                        I've been experimenting with different art styles and techniques to find a balance between visual appeal and development time.</p>
                    <h1>Next Steps:</h1>
                    <p>- Refinement: I plan to continue refining the core gameplay mechanics based on feedback from playtesting sessions. 
                        This includes tweaking cooking times, adjusting ingredient quantities, and fine-tuning player movement controls.</p>
                    <p>- Art Asset Creation: I'll be dedicating more time to creating art assets for the game, including character sprites, food items, and background elements. 
                        I aim to create a cohesive visual style that enhances the overall player experience.</p>
                    <p>- Main World Design: I am currently designing the main into a stylized colorful yet realistic in blender which distinguable details and lively atmosphere.</p>
                    <p>- New Recipes: New ingredients means new burgers means new challenges</p>
                    <p>- More missions: So there are two different types of missions, truck missions and restaurant missions. Truck missions are missions you drive with your truck to and serve from your truck so the 
                        scene is always the same. Restaurant missions on the other side are different each time and they come with their environment challenges.
                    </p>
                    <p>- Sound Design: Sound effects and background music play a crucial role in immersing players in the game world.
                         I'll be working on implementing sound effects for cooking actions, customer interactions, and background ambience to enhance the audio experience.</p>
                    <h1>Closing Thoughts:</h1>
                    <p>Overall, development progress on StreetBites Odyssey is moving steadily forward. While there have been challenges along the way, 
                        I'm excited about the direction the game is taking and look forward to sharing further updates in the next devlog entry.</p>

                    <p>That's all for today's devlog! Stay tuned for more updates on StreetBites Odyssey.</p>
            </div>
        </body>

    )
}

export default StreetBitesUpdates