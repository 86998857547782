import React from 'react'
import { Link } from "react-router-dom";
import "../portfolio/portfolio.css"
import IMG1 from "../../Assets/Drinkapp/Artboard.png"
import IMG2 from "../../Assets/StreetBite/StreetBiteCover.jpg"
import IMG3 from "../../Assets/Invetory Management System/ManagerUsers.jpg"
import IMG4 from "../../Assets/Invetory Management System/dashboard.jpg"
import IMG5 from "../../Assets/Invetory Management System/main_screen.jpg"

const data = [
  {
    id:1,
    image: IMG2,
    title: 'StreetBytes Odyssey',
    website: "Steam",
    link: '',
    demo: 'streetbites',
    updatelog: 'Streetbitesupdates',
    timeline: 'streetbitesroadmap',
    skills: [ "C#", "Unity","Blender"],
    description:'StreetBites Odyssey is an Indie video game developed on the Unity platform using C#. Inspired by "Overcooked".'
  },
  {
    id:2,
    image: IMG1,
    title: 'DrinkItGame',
    website: "Google Play",
    link: 'https://play.google.com/store/apps/details?id=com.MahersDesigns.DrinkItGame',
    demo: 'drinkgame',
    updatelog: '',
    timeline: '',
    skills: [ "C#", "Unity", "Figma", "Android"],
    description:'DrinkItGame is a group hangout android game developed on the Unity platform'
  },
  {
    id:3,
    image: IMG3,
    title: 'Inventory Management System',
    website: "Github",
    link: 'https://github.com/maherhms/Inventory-Management-Application',
    demo: '',
    updatelog: '',
    timeline: '',
    skills: [ "C#", ".NET", "WF", "SQL"],
    description:'This repository contains an inventory management system application developed using .NET framework. The application allows users to manage their inventory efficiently, keeping track of items, quantities, and other relevant details.'
  },
  {
    id:3,
    image: IMG4,
    title: 'Weather Dashboard IOT',
    website: "Github",
    link: 'https://github.com/maherhms/Weather-IOT-Dashboard',
    demo: '',
    updatelog: '',
    timeline: '',
    skills: ["NextJS", "Axios", "Supabase","Spline3D", "SwiperJS"],
    description:'This Weather IoT Dashboard is designed to provide real-time weather updates through an intuitive graphical user interface. It integrates various IoT sensors to collect meteorological data, which is then displayed on a dashboard. This project is ideal for anyone interested in climate monitoring or home automation systems.'
  },
  {
    id:4,
    image: IMG5,
    title: "Momentum Task Manager",
    website: "Github",
    link: 'https://github.com/maherhms/Momentum-Task-Manager',
    demo: '',
    updatelog: '',
    timeline: '',
    skills: ["FLET", "Python"],
    description:'Momentum is a beautifully designed task management application built with Flet. It allows users to create, edit, categorize, and track their tasks efficiently.'
  }
]

const Projects = () => {
  return (
    <section id='portfolio'>
      <h5>My Releases</h5>
      <h2>Projects</h2>

      <div className='container portfolio__container_horizental'>
        {
          data.map(({id, image, title, website, link, demo, updatelog, timeline, skills, description}) => {
            return(
              <article key={id} className='portfolio__item_horizental'>
                <div className="portfolio__item_horizental-image">
                  <img src={image} alt={title} />
                </div>
                <div className='portfolio_description_verticle'>
                  <article >
                    <h3>{title}</h3>
                  </article>
                  <article >
                    <div className="portfolio__item-cta">
                      {skills.map((skill, index) => (
                        <a key={index} className='btn-skills'>{skill}</a>
                      ))}
                    </div>
                  </article>
                  <article>
                    {description && <h4>{description}</h4>}
                  </article>
                  <article>
                    <div className="portfolio__item-cta">
                      {link && <a href={link} className='btn'>{website}</a>}
                      {demo && <Link to={demo} className='btn btn-primary' >Devblog</Link>}
                      {updatelog && <Link to={updatelog} className='btn btn-primary' >Updates</Link>}
                      {timeline && <Link to={timeline} className='btn btn-primary' >Roadmap</Link>}
                    </div>
                  </article>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Projects
