import React , {useState} from 'react'
import "./StreetBitesRoadmap.css"
import StreetBitesTimeline from './StreetBitesTimeline';
import StreetBitesTimelineItem from './StreetBitesTimelineItem';

function StreetBitesRoadmap(){
    return(
        <div>
            <h1 className='title'>Timeline</h1>
            <div className='roadmap-container'>
            <div>
                {StreetBitesTimeline.map(item =>(
                    <StreetBitesTimelineItem 
                    year={item.year}
                    title={item.title}
                    duration={item.duration}
                    details={item.details}
                    />
                    ))}
            </div>
        </div>
        </div>
        
    )
}

export default StreetBitesRoadmap;