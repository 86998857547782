import React , {useState} from 'react'
import "./StreetBitesTimelineItem.css"
import StreetBitesTimeline from './StreetBitesTimeline';

function StreetBitesTimelineItem({year , title , duration , details}){
    return(
        <ol className='timeline-item-container'>
            <li>
                <div/>
                <div className="timeline-item-header">
                    <span className='year-badge'>{year}</span>
                    <h3>{title}</h3>
                    <div className='duration-badge'>{duration}</div>
                </div>
                <p>{details}</p>
            </li>
        </ol>
    )
}

export default StreetBitesTimelineItem;