import React from 'react'
import {useRef, useEffect , useState} from "react"
import "./ThreedGallery.css"
import {BiArrowBack} from 'react-icons/bi'
import {IoMdArrowForward} from "react-icons/io"
import { Link } from 'react-router-dom';
import {IoClose} from 'react-icons/io5'

import img1 from "../../Assets/webui/Cart-4003a.png"
import img2 from "../../Assets/webui/Cart-60056.png"
import img3 from "../../Assets/webui/Cart-8a61e.png"
import img4 from "../../Assets/webui/Home-06d17.png"
import img5 from "../../Assets/webui/Home-13fed.png"
import img6 from "../../Assets/webui/Home-83977.png"
import img7 from "../../Assets/webui/Home-8a79f.png"
import img8 from "../../Assets/webui/Login-7ec6a.png"
import img9 from "../../Assets/webui/MediaPlayer-07bb0.png"
import img10 from "../../Assets/webui/Notifications-5954b.png"
import img11 from "../../Assets/webui/Onboarding-4e791.png"

function WebDesign() {

    let data = [
        {
            id: 1,
            imgSrc: img1,
        },
        {
            id: 2,
            imgSrc: img2,
        },
        {
            id: 3,
            imgSrc: img3,
        },
        {
            id: 4,
            imgSrc: img4,
        },
        {
            id: 5,
            imgSrc: img5,
        },
        {
            id: 6,
            imgSrc: img6,
        },
        {
            id: 7,
            imgSrc: img7,
        },
        {
            id: 8,
            imgSrc: img8,
        },
        {
            id: 9,
            imgSrc: img9,
        },
        {
            id: 10,
            imgSrc: img10,
        },
        {
            id: 11,
            imgSrc: img11,
        }
    ]

    let [model , setModel] = useState(false);
    let [tmpImgSrc , setTmpImgSrc] = useState('');
    let getImg = (imgSrc) => {
        setTmpImgSrc(imgSrc);
        setModel(true);
    }

  return (
      <>
      <div className={model? "model open" : "model"}>
         <img src={tmpImgSrc} />
         <IoClose onClick={()=> setModel(false)}/>
      </div>
      
      <div className='links'>
      <Link to="/">
        <div className='homebtn'>
                <BiArrowBack size={20}/>
                <h3>Home</h3>
        </div>
      </Link>
      <Link to="/interior-design">
        <div className='homebtn'>
                <h3>Order</h3>
                <IoMdArrowForward size={20}/>
        </div>
      </Link>
      </div>
      
      <div className='title'>
          <h1>Website Design</h1>
      </div>
      <div className='gallery'>
            {data.map((item , index) => {
                return(
                    <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                        <img src={item.imgSrc} style={{width:'100%'}} />
                    </div>
                )
            })}
        </div>
    </>
  )
}

export default WebDesign