import React from 'react'

import { HashRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/header/Header"
import Nav from "./components/nav/Nav"
import Navbar from './components/Navbar/Navbar';
import About from "./components/about/About"
import Experience from "./components/experience/Experience"
import Services from "./components/services/Services"
import Portfolio from "./components/portfolio/Portfolio"
import Projects from "./components/Projects/projects"
import Testimonials from "./components/testimonials/Testimonials"
import Contact from "./components/contact/Contact"
import Footer from "./components/footer/Footer"
import ThreedGallery from './components/Gallery/ThreedGallery';
import UIXGallery from './components/Gallery/UIXGallery';
import Drinkapp from './components/drinkapp/Drinkapp';
import StreetBites from './components/StreetBites/StreetBites';
import ReleaseBanner from './components/Advertisement/ReleaseBanner';
import StreetBitesUpdates from './components/StreetBites/StreetBitesUpdates';
import InteriorDesign from './components/Gallery/InteriorDesign';
import WebDesign from './components/Gallery/WebDesign';
import StreetBitesRoadmap from './components/StreetBites/StreetBitesRoadmap';

const App = () => {
  return (
    <>
        <Router>
          <Routes>
              <Route path={"/"} element={<>
                    {/* <ReleaseBanner/>
                    <Header/> */}
                    <Nav/>
                    <About/>
                    <Experience/>
                    <Projects/>
                    <Portfolio/>
                    {/* <Services/> */}
                    <Testimonials/>
                    <Contact/>
                    <Footer/></>}>
              </Route>
              <Route path="/interior-design" element={<><Navbar/></>}></Route>
              <Route path="/3d-design-gallery" element={<><ThreedGallery/></>}></Route>
              <Route path="/ui-app-gallery" element={<><UIXGallery/></>}></Route>
              <Route path="/web-ui-gallery" element={<><WebDesign/></>}></Route>
              <Route path="/drinkgame" element={<><Drinkapp/></>}></Route>
              <Route path="/streetbites" element={<><StreetBites/></>}></Route>
              <Route path='/Streetbitesupdates' element={<><StreetBitesUpdates/></>} ></Route>
              <Route  path='/3dinteriordesign' element={<><InteriorDesign/></>} ></Route>
              <Route path='/streetbitesroadmap' element={<><StreetBitesRoadmap/></>} ></Route>
          </Routes>
        </Router>
    </>
  );
}

export default App