import React from 'react'
import { Link } from "react-router-dom";
import "./portfolio.css"
import IMG1 from "../../Assets/Portfolio/3DCollage.jpg"
import IMG2 from "../../Assets/Portfolio/InteriorCollage.jpg"
import IMG3 from "../../Assets/Portfolio/uiuxcollage.jpg"
import IMG4 from "../../Assets/webui/Minimalist Neutral Multi Device Computer Mockup Website Launch Instagram Post.png"

const data = [
  {
    id:1,
    image: IMG1,
    title: '3D Isometric Design',
    website: "Instagram",
    link: 'https://www.instagram.com/devnest.contact/',
    demo: '3d-design-gallery'
  },
  {
    id:2,
    image: IMG2,
    title: '3D Interior Design',
    website: "Instagram",
    link: 'https://www.instagram.com/devnest.contact/',
    demo: '3dinteriordesign'
  },
  {
    id:3,
    image: IMG3,
    title: 'Mobile app Design',
    website: "",
    link: 'https://www.instagram.com/devnest.contact/',
    demo: 'ui-app-gallery'
  },
  {
    id:4,
    image: IMG4,
    title: 'Web app Design',
    website: "",
    link: 'https://www.instagram.com/devnest.contact/',
    demo: 'web-ui-gallery'
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
          data.map(({id, image, title, website, link, demo}) => {
            return(
              <article key={id} className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={image} alt={title} />
          </div>
          <h3>{title}</h3>
          <div className="portfolio__item-cta">
          <Link to={demo} className='btn btn-primary' >Gallery</Link>
          {website !="" ? <a href={link} className='btn'>{website}</a> : null}
          </div>
        </article>
            )
          })
        }
        
      </div>
    </section>
  )
}

export default Portfolio