import React from 'react'
import {motion} from "framer-motion";
import {useRef, useEffect , useState} from "react"
import "./UIXGallery.css"

import {BiArrowBack} from 'react-icons/bi'
import {IoMdArrowForward} from "react-icons/io"
import images from "./uiimages"
import { Link } from 'react-router-dom';

function UIXGallery() {
    const[width , setWdith] = useState(0);
    const carousel = useRef();

    useEffect(() => {
        setWdith(carousel.current.scrollWidth - carousel.current.offsetWidth);
    }, []);

  return (
    <>
    <div className='links'>
      <Link to="/">
        <div className='homebtn'>
                <BiArrowBack size={20}/>
                <h3>Home</h3>
        </div>
      </Link>
      <Link to="/">
        <div className='homebtn'>
                <h3>Order</h3>
                <IoMdArrowForward size={20}/>
        </div>
      </Link>
      </div>
      <div className='title'>
          <h1>3D Interior Design</h1>
      </div>
    <div className="gallery-body">
        <motion.div ref={carousel} className='carousel' whileTap={{cursor:"grabbing"}}>
            <motion.div 
            drag="x" 
            dragConstraints={{right: 0 , left: -width}}
            className='inner-carousel'>
                {images.map(image => {
                    return(
                        <motion.div className='item' key={image}>
                            <img src={image} alt=""></img>
                        </motion.div>
                    );
                })}
            </motion.div>
        </motion.div>
    </div>
    </>
  )
}

export default UIXGallery